<template>
  <div>
    <a :class="className" href="#" @click.prevent="popupOpen = true">
      <i class="fa fa-phone" />
      <span class="profile-list-value">{{ label || number }}</span>
    </a>
    <Teleport to="body">
      <PhoneCallModal
        v-if="popupOpen"
        :initialNumber="number"
        :name="name"
        :gigyaId="gigyaId"
        @close="popupOpen = false"
      />
    </Teleport>
  </div>
</template>

<script>
import PhoneCallModal from '../../phone-system/PhoneCallModal.vue'

// this is the phone link for vue components, for the rails variant: app/helpers/profile_helper.rb
export default {
  name: 'PhoneToLink',
  components: {
    PhoneCallModal,
  },
  props: {
    number: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    gigyaId: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
      default: 'profile-identifier',
    },
  },
  data() {
    return {
      popupOpen: false,
    }
  },
}
</script>

<style></style>
