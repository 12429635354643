<template>
  <div>
    <div class="message">
      <div class="user-link" @click="showProfileModal">{{ message.profile }}</div>
      <span v-if="message.city" class="city"> uit {{ message.city }}</span>
      (<PhoneToLink className="phone-link" :name="message.profile" :number="message.phone" label="bel" />):
      <strong>
        <slot>
          {{ message.message }}
        </slot>
      </strong>
    </div>
    <template v-if="!hideAttachments">
      <MessageImage v-if="hasImage" :media="message.media" />
      <AudioPlayerWithOmniplayerExport
        v-else-if="showAudioPlayer"
        class="audio-player"
        :filename="message.media.file_name"
        :showVideo="hasVideo"
        :transcription="message.media.transcription"
        :url="message.media.media_url"
        :exportUrl="message.media.export_url"
      />
    </template>
  </div>
</template>

<script>
import PhoneToLink from '../../general/components/PhoneToLink.vue'
import MessageImage from './MessageImage.vue'
import AudioPlayerWithOmniplayerExport from '../../general/components/AudioPlayerWithOmniplayerExport.vue'

export default {
  components: {
    PhoneToLink,
    MessageImage,
    AudioPlayerWithOmniplayerExport,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    subject() {
      if (this.city) {
        return `${this.message.profile} uit ${this.message.city} zegt:`
      } else {
        return `${this.message.profile} zegt:`
      }
    },
    mediaType() {
      return this.message?.media?.type
    },
    hasImage() {
      return this.mediaType === 'image'
    },
    showAudioPlayer() {
      return this.mediaType === 'audio' || this.mediaType === 'video'
    },
    hasVideo() {
      return this.mediaType === 'video'
    },
    userLink() {
      return '/production/messages/' + this.message.id + '/replies/new'
    },
    hideAttachments() {
      return this.message.hideAttachments
    },
  },
  methods: {
    showProfileModal() {
      const modal = new window.QAPI.Views.Production.ProfileListLinkProfileModalView({ url: this.userLink })
      modal.render()
    },
  },
}
</script>

<style scoped lang="scss">
.phone-link,
.audio-player {
  margin-top: 1rem;
}

.message {
  display: flex;
  gap: 2px;
  clear: both;
}

.user-link {
  color: #4083a9;
  text-decoration: underline;
  cursor: pointer;
}

.user-link,
.city {
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

<style>
.phone-link {
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
