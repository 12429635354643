<template>
  <ImproveAudio
    :originalUrl="originalUrl"
    :improvedUrl="currentImprovedUrl"
    :improveUrl="improveUrl"
    :improving="improving"
  >
    <template #default="{ url, filename, improved }">
      <AudioPlayerWithOmniplayerExport
        :key="url"
        :url="url"
        :transcription="currentTranscription"
        :filename="filename"
        :exportUrl="exportUrl"
        :improved="improved"
      />
    </template>
  </ImproveAudio>
</template>

<script>
// This component exists due to unsupported scoped slots in our Rails component tags.
import ImproveAudio from './ImproveAudio.vue'
import AudioPlayerWithOmniplayerExport from './AudioPlayerWithOmniplayerExport.vue'

export default {
  components: { ImproveAudio, AudioPlayerWithOmniplayerExport },
  props: ['messageId', 'transcription', 'exportUrl', 'originalUrl', 'improvedUrl', 'improveUrl'],
  data() {
    return {
      currentTranscription: this.transcription,
      currentImprovedUrl: this.improvedUrl,
      improving: false,
    }
  },
  mounted() {
    window.transcriptionListeners[this.messageId] = [
      ...(window.transcriptionListeners[this.messageId] || []),
      (transcription) => (this.currentTranscription = transcription),
    ]

    window.audioImprovingListeners[this.messageId] = [
      ...(window.audioImprovingListeners[this.messageId] || []),
      () => (this.improving = true),
    ]

    window.audioImproveListeners[this.messageId] = [
      ...(window.audioImproveListeners[this.messageId] || []),
      (improvedUrl) => {
        this.currentImprovedUrl = improvedUrl
        this.improving = false
      },
    ]
  },
}
</script>
