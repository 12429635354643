<template>
  <div class="llm-chat">
    <div class="llm-chat__content">
      <div v-if="newChat">
        <div class="chat-preview">
          <div class="logo">
            <img class="doria-logo" src="../../assets/images/doria.png" />
            <h2>Heey, ik ben Doria, hoe kan ik je helpen?</h2>
          </div>

          <div class="examples">
            <div class="block">
              <h4>Wat kan ik?</h4>
              Teksten samenvatten, en terug geven in een korte lijst van bullet points
            </div>
            <div class="block">
              <h4>Waar kan je me niet voor gebruiken?</h4>
              Ik kan soms verkeerde informatie geven over weetjes van Artiesten, liedjes, datums ...
            </div>
            <div class="block">
              <h4>Nog enkele voorbeelden</h4>
              Verzin 5 quiz vragen voor jongens, Schrijf een doorlopende tekst met volgende woorden: Dj, Foute party, 31
              juni, kom je ook
            </div>
          </div>
        </div>
        <chatSettings class="chat-settings" :chatId="chatId" />
      </div>
      <div class="llm-chat__conversation">
        <div ref="chat">
          <component
            :is="messageComponent(message)"
            v-for="message in messages"
            :key="message.content"
            :message="message.content"
          />
          <llmMessage v-if="isGenerating" loading="true" :message="generatedContent" />
        </div>
      </div>
    </div>
    <div class="llm-chat__footer">
      <div class="prompt-input">
        <textarea
          ref="prompt"
          v-model="prompt"
          :disabled="isGenerating"
          type="text"
          placeholder="Wat wil je mij vragen?"
          @keydown.enter.exact.prevent="send()"
        />
        <button :disabled="isGenerating" @click="send()">
          <i class="fa fa-send" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserMessage from './components/userMessage.vue'
import LlmMessage from './components/llmMessage.vue'
import ChatSettings from './components/chatSettings.vue'

export default {
  name: 'LlmChat',
  components: { LlmMessage, UserMessage, ChatSettings },
  props: ['chatId'],
  data() {
    return {
      prompt: '',
    }
  },
  computed: {
    ...mapGetters({
      chatForId: 'LlmConversation/chatForId',
      generatedContentForChat: 'LlmConversation/generatedContentForChat',
    }),
    chat() {
      return this.chatForId(this.chatId)
    },
    generatedContent() {
      return this.generatedContentForChat(this.chat)
    },
    isGenerating() {
      return this.chat?.status === 'running'
    },
    messages() {
      return this.chat?.messages
    },
    newChat() {
      return this.messages?.length === 0
    },
  },
  mounted() {
    this.subscribe(this.chatId)
    this.$refs.prompt.focus()
  },
  methods: {
    ...mapActions({
      sendMessage: 'LlmConversation/sendMessage',
      subscribe: 'LlmConversation/subscribe',
    }),
    send() {
      this.sendMessage({ chatId: this.chatId, content: this.prompt })
      this.scrollToBottom()
      this.prompt = ''
    },
    messageComponent(message) {
      return message.role === 'user' ? UserMessage : LlmMessage
    },
    scrollToBottom() {
      const lastChildElement = this.$refs.chat.lastElementChild
      lastChildElement?.scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.llm-chat {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 70px;
  grid-template-areas:
    'content'
    'footer';

  &__footer {
    height: 80px;
    grid-area: footer;
    padding: 10px 30px;

    .prompt-input {
      position: relative;
      height: 50px;
      border-radius: 7px;

      textarea {
        width: calc(100% - 60px);
        padding: 1em 1em 1em 1em;
        box-sizing: border-box;
        padding-right: 35px;
        height: 100%;
        background: white;
        border-radius: 7px;
        outline-color: var(--mediumblue);
        border-color: none;

        &:disabled {
          background: darken(white, 20%);
        }
      }

      button {
        background: var(--mediumblue);
        z-index: 2;
        color: white;
        border-radius: 0px 7px 7px 0px;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 15px 30px;
        cursor: pointer;
        border: none;

        &:disabled {
          cursor: not-allowed;
          background: var(--mediumblue-dark);
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    overflow-y: auto;
    padding: 25px 30px;
  }

  &__conversation {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    padding-right: 20px;
  }
}

.chat-preview {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    font-weight: 600;
  }

  .logo {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .doria-logo {
      height: 150px;
      aspect-ratio: 1/1;
    }
  }

  @media screen and (max-height: 900px) {
    .logo {
      height: 70px;
    }
    .doria-logo {
      display: none;
    }
  }

  .examples {
    display: flex;
    flex-direction: column;
    align-items: center;

    .block {
      width: 700px;
      max-width: 100%;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 7px;
      background: white;

      h4 {
        margin: 0;
      }
    }
  }
}
</style>
