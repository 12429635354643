<template>
  <AudioPlayer @selectRegion="region = $event" @monoChannel="monoChannel = $event">
    <slot />
    <button
      class="btn-xs btn btn-with-icon"
      :class="buttonClass"
      :disabled="state === 'uploading'"
      @click="exportToOmniplayer"
    >
      <i :class="`fa ${iconClass} avoid-clicks mr`" />
      <span v-html="buttonText" />
    </button>
  </AudioPlayer>
</template>

<script>
import api from '../../store/API'
import AudioPlayer from './AudioPlayer.vue'

export default {
  components: {
    AudioPlayer,
  },
  props: ['exportUrl', 'exportMono', 'improved'],
  data() {
    return {
      state: 'idle',
      region: null,
      monoChannel: null,
    }
  },
  computed: {
    buttonClass() {
      return {
        'btn-warning': this.state === 'idle' && this.region,
        'btn-default': this.state === 'idle',
        'btn-primary': this.state === 'uploading',
        'btn-danger': this.state === 'error',
        'btn-success': this.state === 'success',
      }
    },
    buttonText() {
      return {
        idle: this.region ? '<strong>Selectie</strong> exporteren naar Omniplayer' : 'Exporteren naar Omniplayer',
        uploading: 'Bezig met uploaden',
        error: 'Er is iets misgegaan',
        success: 'Klaar met uploaden!',
      }[this.state]
    },
    iconClass() {
      return {
        idle: 'fa-share-square-o',
        uploading: 'fa-spinner fa-spin',
        error: 'fa-exclamation-triangle',
        success: 'fa-check',
      }[this.state]
    },
  },
  watch: {
    region() {
      if (this.state !== 'uploading') {
        this.state = 'idle'
      }
    },
  },
  methods: {
    async exportToOmniplayer() {
      this.state = 'uploading'
      try {
        await api.post(this.exportUrl, {
          region: this.region,
          monotize: this.monoChannel === 0 ? 'left' : this.monoChannel === 1 ? 'right' : this.exportMono,
          improved: this.improved,
        })
        this.state = 'success'
      } catch (error) {
        this.state = 'error'
        setTimeout(() => (this.state = 'idle'), 5000)
        throw error
      }
    },
  },
}
</script>

<style scoped>
button i {
  margin-right: 0.25em;
}
</style>
