<template>
  <div class="suggestions">
    <div class="suggestions-container">
      <ul>
        <li v-for="i in suggestions.length || 3" :key="i">
          <button
            class="select"
            :disabled="!finishedGenerating"
            @click="$emit('replySelected', joinedSuggestions[i - 1])"
          >
            <span class="doria-container">
              <img class="doria-logo" src="../../assets/images/doria.png" />
            </span>
            <span class="content-container">
              <template v-if="joinedSuggestions[i - 1]">
                <span v-text="joinedSuggestions[i - 1]" />
              </template>
              <LlmReplySuggestionSkeleton v-else class="skeleton" />
              <i class="fa fa-pencil" />
            </span>
          </button>
          <button class="send" :disabled="!finishedGenerating" @click="$emit('replySend', joinedSuggestions[i - 1])">
            <i class="fa fa-send" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '../store/API'
import socket from '../store/PublicSocket'
import LlmReplySuggestionSkeleton from './LlmReplySuggestionSkeleton.vue'

export default {
  components: { LlmReplySuggestionSkeleton },

  props: {
    messageId: { type: Number, required: true },
    sender: { type: Object, required: true },
  },

  emits: ['replySelected', 'replySend'],

  data: () => ({
    lSocket: null,

    suggestions: [],
    finishedGenerating: false,
    generateUuid: null,
  }),

  computed: {
    joinedSuggestions() {
      return this.suggestions.map((x) => x.join(''))
    },
  },

  watch: {
    'sender.id'(newValue) {
      this.getSuggestions(newValue)
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.getSuggestions()
    })
  },

  unmounted() {
    this.unsubscribe()
  },

  methods: {
    async getSuggestions(sender_id) {
      this.suggestions = []
      this.finishedGenerating = false

      await this.unsubscribe(this.generateUuid)
      this.generateUuid = crypto.randomUUID()
      await this.subscribe(this.generateUuid)

      const params = {
        dj_id: sender_id?.startsWith?.('dj:') ? sender_id.replace('dj:', '') : null,
        uuid: this.generateUuid,
      }

      await api.post(`/production/messages/${this.$props.messageId}/reply_suggestions`, params)
    },

    async subscribe(generateUuid) {
      this.lSocket = await socket()

      this.lSocket
        .subscribe({
          'dario:message_suggestion': `${this.$props.messageId}-${generateUuid}`,
        })
        .on('change', ({ content: { suggestion, content }, idx }) => {
          if (!this.suggestions[suggestion]) {
            this.suggestions[suggestion] = []
          }

          this.suggestions[suggestion][idx] = content
        })
        .on('finish', () => {
          this.finishedGenerating = true
        })
    },

    async unsubscribe() {
      this.lSocket = null
    },
  },
}
</script>

<style lang="scss" scoped>
.suggestions {
  margin-top: 10px;

  .suggestions-container {
    > ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      > li {
        display: flex;
        overflow: hidden;
        border-radius: 15px 5px 5px 0;
        margin-bottom: 5px;

        border: 1px solid transparent;

        &:last-of-type {
          margin-bottom: 0;
        }

        > button.select {
          display: flex;
          align-items: normal;
          width: 100%;
          text-align: left;

          overflow: hidden;

          border: 0;
          margin: 0;
          padding: 0;

          font-weight: normal;

          > .doria-container {
            display: flex;
            align-items: center;
            background-color: #9f9c9c;
            padding: 5px 8px;

            > img {
              width: 25px;
            }
          }

          > .content-container {
            display: flex;
            align-items: center;
            flex: 1;

            background-color: #c5c3c3;
            vertical-align: middle;

            padding: 5px 8px;
            padding-right: 10px;

            > span,
            > .skeleton {
              flex: 1;
              margin-right: 8px;
            }
          }
        }

        button.send {
          border: 0;
          background-color: #6a6463;
          color: white;
          width: 35px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
</style>
