document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.profiles.show')) {
    // Javascript to enable link to tab
    // http://stackoverflow.com/a/10787789/1421542
    const hash = document.location.hash
    const prefix = 'tab_'
    if (hash) {
      window.jQuery('.nav-tabs a[href="' + hash.replace(prefix, '') + '"]').tab('show')
    }

    // this is a jQuery only event
    window.jQuery('.nav-tabs a').on('shown.bs.tab', function (e) {
      window.location.hash = e.target.hash.replace('#', '#' + prefix)
    })
  }

  document.querySelectorAll('.profile-rater-wrapper').forEach(function (wrapper) {
    // eslint-disable-next-line no-undef
    new Production.ProfileRater({ el: wrapper })
  })

  document.addEventListener('click', (event) => {
    const button = event.target

    // Handle assign-prize
    if (button.getAttribute('data-role') === 'assign-prize') {
      event.preventDefault()
      const profileId = button.getAttribute('data-profile-id')

      const span = document.createElement('span')
      span.setAttribute('data-component', 'select-winners')
      document.body.appendChild(span)

      $.SelectContestWinnersModalInstance = window.initializeSelectWinners(
        span,
        function () {
          return [profileId]
        },
        function () {
          document.location.reload()
        }
      )
    }

    if (button.getAttribute('data-role') === 'copy-gigya-id') {
      const gigyaId = button.getAttribute('data-attr')
      const tempInput = document.createElement('textarea')
      document.body.appendChild(tempInput)
      tempInput.value = gigyaId
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
    }
  })
})
