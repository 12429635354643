import { Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import api from '@/store/API'
import VueMessageNode from '@/scenario/message/MessageNode.vue'

export const MessageNode = Node.create({
  name: 'messageNode',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      message: {
        default: null,
      },
    }
  },

  group: 'block',
  atom: false,
  content: 'inline*',
  draggable: true,
  isolating: false,

  parseHTML() {
    return [
      {
        tag: 'div[data-component="ScenarioMessage"]',
        getAttrs: (dom) => {
          return {
            message: JSON.parse(dom.getAttribute('data-message')),
          }
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { message } = HTMLAttributes
    return [
      'div',
      {
        'data-component': 'ScenarioMessage',
        'data-message': JSON.stringify(message),
      },
      0, // this is the innerHTML, it will be replaced
    ]
  },

  addNodeView() {
    return VueNodeViewRenderer(VueMessageNode)
  },
})

export const handlePaste = async (view, { id }) => {
  const message = (await api.get(`/production/messages/${id}`)).data

  return view.state.schema.nodes.messageNode.create(
    {
      message: {
        id: message.id,
        message: message.message,
        profile: message.from.name,
        phone: message.from.phone,
        city: message.from.city,
        media: message.media,
      },
    },
    view.state.schema.text(message.message || ' ') // empty text nodes are not allowed, so we add a space
  )
}
