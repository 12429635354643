import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { handlePaste as handlePasteMessage } from '../nodes/message'
import { airbrake } from '@/general/airbrake'

const pasteRules = {
  'message-node': handlePasteMessage,
}

export const PasteHandler = Extension.create({
  name: 'handlePaste',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('handlePastePlugin'),
        props: {
          handlePaste(view, event) {
            const pastedJSON = event.clipboardData.getData('application/json+dario')

            if (!pastedJSON) {
              return false
            }

            const jsonData = JSON.parse(pastedJSON)

            const { from } = view.state.selection
            const nodePromises = []

            jsonData.map((node) => {
              const { type, ...attrs } = node

              if (pasteRules[type]) {
                nodePromises.push(pasteRules[type](view, attrs))
              }
            })

            Promise.all(nodePromises)
              .then((nodes) => {
                let pos = from
                const tr = view.state.tr

                nodes.forEach((node) => {
                  if (node) {
                    tr.insert(pos, node)
                    pos += node.nodeSize
                  }
                })

                view.dispatch(tr)
                event.preventDefault()
              })
              .catch((error) => {
                airbrake?.notify({
                  error: error,
                  params: { info: 'Failed to paste message in scenario' },
                })
                alert('Het plakken is mislukt, probeer het later opnieuw')
              })

            return true
          },
        },
      }),
    ]
  },
})
