/*
  This will listen for dragStart events on elements with a data-drag-type attribute.
  To use this:
   - add a data-drag-type attribute to the element you want to drag.
   - add a handler to the DRAG_TYPE_HANDLER object that returns the data you want to pass to the drop event.
   - The data will be stored in the dataTransfer object as 'application/json+dario' as an array of objects (to support multiple elements).
*/

let noDrag = false

export default function handleDragStart() {
  document.addEventListener('dragstart', (event) => {
    if (noDrag) {
      event.preventDefault()
      return
    }
    const dragType = event.target.getAttribute('data-drag-type')
    if (!dragType) return

    event.dataTransfer.setData('application/json+dario', JSON.stringify(DRAG_TYPE_HANDLER[dragType](event)))
  })

  document.addEventListener('mousedown', (event) => {
    const noDragElement = event.target.closest('[data-no-drag]')
    if (noDragElement) {
      noDrag = true
      document.addEventListener('mouseup', () => (noDrag = false), { once: true })
    }
  })
}

const handleDragMessage = (event) => {
  const node = event.srcElement.querySelector('[data-clipboard-json-message]')
  if (node) {
    return [JSON.parse(node.dataset.clipboardJsonMessage)]
  } else {
    console.warn('No element with data-clipboard-json-message attribute found.')
    return null
  }
}

const DRAG_TYPE_HANDLER = {
  'message-node': handleDragMessage,
}

document.addEventListener('DOMContentLoaded', handleDragStart)
