<template>
  <img :src="media.thumbnail_url" alt="media" class="message-image" @click="open = true" />
  <ApiModal v-if="open" @close="open = false">
    <template #header>
      <h4>Afbeelding</h4>
    </template>
    <img :src="media.media_url" alt="media" style="max-width: 100%" />
  </ApiModal>
</template>

<script>
import ApiModal from '../../general/components/ApiModal.vue'

export default {
  components: {
    ApiModal,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>

<style scoped lang="scss">
.message-image {
  cursor: pointer;
  max-height: 100px;
}
</style>
