<template>
  <div class="message">
    <img class="message__img" src="../../../assets/images/doria.png" />

    <div class="message__bubble">
      <div class="message__info">DORIA</div>
      <div class="message__text">
        <div v-html="formattedMessage" />
        <div v-if="loading" class="loading">
          <div class="ellipsis one" />
          <div class="ellipsis two" />
          <div class="ellipsis three" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { markdown } from '@/general/utils/markdown'

export default {
  name: 'LlmMessage',
  props: ['message', 'loading'],
  computed: {
    formattedMessage() {
      return markdown(this.message)
    },
  },
}
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  &:last-of-type {
    margin: 0;
  }

  &__img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }

  &__bubble {
    max-width: 70%;
    padding: 15px;
    border-radius: 15px;
    background: white;
    border-bottom-left-radius: 0;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: 5px;
    font-weight: bold;
  }
}

.ellipsis {
  width: 2px;
  height: 2px;
  margin: 2px;
  display: inline-block;
  background: var(--mediumblue);
  border-radius: 50%;
  animation: bounce 1.3s linear infinite;

  &.one {
    animation-delay: 0.6s;
  }

  &.two {
    animation-delay: 0.5s;
  }

  &.three {
    animation-delay: 0.8s;
  }
}

.loading {
  display: inline-block;
  color: var(--mediumblue);
  font-size: 0.7em;
}

@keyframes bounce {
  30% {
    transform: translateY(-1px);
  }

  60% {
    transform: translateY(0px);
  }

  80% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}
</style>
