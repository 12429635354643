<template>
  <node-view-wrapper class="vue-component draggable-item">
    <div class="drag-handle" data-drag-handle draggable="true" contentEditable="false" />
    <Message class="message-item" :message="message">
      <node-view-content />
    </Message>
    <div class="actions">
      <i v-if="hasMedia" class="fa" :class="attachmentIcon" @click="toggleAttachments" />
      <i class="fa fa-times" @click="deleteNode" />
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewContent, NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import Message from './Message.vue'

export default {
  components: {
    NodeViewWrapper,
    Message,
    NodeViewContent,
  },
  props: nodeViewProps,
  computed: {
    message() {
      return this.node.attrs.message
    },
    attachmentIcon() {
      return this.message.hideAttachments ? 'fa-expand' : 'fa-compress'
    },
    hasMedia() {
      return !!this.message.media?.type
    },
  },
  methods: {
    toggleAttachments() {
      this.node.attrs.message.hideAttachments = !this.node.attrs.message.hideAttachments
    },
  },
}
</script>

<style scoped lang="scss">
.draggable-item {
  display: flex;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  background: white;
  border: 0.5px solid #e2e8f0;

  .drag-handle {
    flex: 0 0 auto;
    position: relative;
    width: 1rem;
    height: 1rem;
    top: 0.3rem;
    margin-right: 0.5rem;
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .content {
    flex: 1 1 auto;
  }

  .message-item {
    flex: 1 1 auto;
    margin: 0;
  }

  .content-dom {
    display: none;
  }

  &:hover {
    .actions {
      display: flex;
      gap: 8px;
      cursor: pointer;
    }
  }

  .actions {
    flex: 0 0 auto;
    display: none;
    align-items: center;
    margin-left: 0.5rem;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
}
</style>
