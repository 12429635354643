import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { handlePaste as handlePasteMessage } from '../nodes/message'
import { airbrake } from '@/general/airbrake'

const dropRules = {
  // paste and drop are the same for message-node
  'message-node': handlePasteMessage,
}

export const DropHandler = Extension.create({
  name: 'handleDrop',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('handleDropPlugin'),
        props: {
          handleDrop(view, event) {
            const pastedJSON = event.dataTransfer.getData('application/json+dario')

            if (!pastedJSON) {
              return false
            }

            const jsonData = JSON.parse(pastedJSON)
            const nodePromises = []

            jsonData.map((node) => {
              const { type, ...attrs } = node

              if (dropRules[type]) {
                nodePromises.push(dropRules[type](view, attrs))
              }
            })

            // Get the position of the drop and get corresponding position in the document
            const coords = { left: event.clientX, top: event.clientY }
            let pos = view.posAtCoords(coords)?.pos
            const tr = view.state.tr

            if (pos == null) {
              return false
            }

            Promise.all(nodePromises)
              .then((nodes) => {
                nodes.forEach((node) => {
                  if (node) {
                    tr.insert(pos, node)
                    pos += node.nodeSize
                  }
                })

                view.dispatch(tr)
                event.preventDefault()
              })
              .catch((error) => {
                console.error('Failed to drop element in scenario', error)
                airbrake?.notify({
                  error: error,
                  params: { info: 'Failed to drop element in scenario' },
                })
                alert('Het droppen is mislukt, probeer het later opnieuw')
              })

            return true
          },
        },
      }),
    ]
  },
})
